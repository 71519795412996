export default [
  {
    header: 'admin.navigation.pages_list',
  },
  {
    title: 'admin.navigation.pages',
    icon: 'ColumnsIcon',
    route: 'pages-index',
    action: 'index',
    resource: 'pages',
  },
]
