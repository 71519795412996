export default [
  {
    header: 'admin.navigation.others',
  },
  {
    title: 'admin.navigation.shops',
    route: 'shops-index',
    icon: 'NavigationIcon',
    action: 'index',
    resource: 'shops',
  },
  {
    title: 'admin.navigation.reviews',
    route: 'reviews-index',
    icon: 'PenToolIcon',
    action: 'index',
    resource: 'reviews',
  },
  {
    title: 'admin.navigation.extensions',
    route: 'extensions-index',
    icon: 'ExternalLinkIcon',
    action: 'index',
    resource: 'extensions',
  },
  {
    title: 'admin.navigation.orders',
    route: 'orders-index',
    icon: 'ShoppingBagIcon',
    action: 'index',
    resource: 'orders',
  },
]
