export default [
  {
    header: 'admin.navigation.system_pages',
  },
  {
    title: 'admin.navigation.system',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'admin.navigation.file-manager',
        route: 'file-manager',
        action: 'index',
        resource: 'settings',
      },
      {
        title: 'admin.navigation.settings',
        route: 'settings',
        action: 'index',
        resource: 'settings',
      },
      {
        title: 'admin.navigation.languages',
        route: 'languages',
        action: 'index',
        resource: 'languages',
      },
      {
        title: 'admin.navigation.translations',
        route: 'translations',
        action: 'index',
        resource: 'translations',
      },
    ],
  },
]
