export default [
  {
    header: 'admin.navigation.devices',
  },
  {
    title: 'admin.navigation.catalog',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'admin.navigation.devices',
        route: 'devices-index',
        action: 'index',
        resource: 'devices',
      },
      {
        title: 'admin.navigation.models',
        route: 'models-index',
        action: 'index',
        resource: 'models',
      },
      {
        title: 'admin.navigation.problems',
        route: 'problems-index',
        action: 'index',
        resource: 'problems',
      },
      {
        title: 'admin.navigation.tips',
        route: 'tips-index',
        action: 'index',
        resource: 'tips',
      },
    ],
  },
]
